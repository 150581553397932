import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <Seo />
      <div className="font-roboto-slab 2xl:text-sm text-lg px-10 pb-10 lg:pb-0 2xl:pl-0">
        <p className="mb-3">Hi, I'm Francis.</p>
        <p className="mb-3">Pleased to meet you</p>
        <p className="mb-3">I'm a web developer who has been working in the field for eight years. I've worked with a wide range of clients, from small startups to mid-size companies, and I've helped them build everything from simple websites to complex client requests.</p>
        <p className="mb-3">I love the challenge of building something new, and I'm especially excited about the opportunity to work with you on your project.</p>
        <p>Let me know if you're interested. Here's my CV, click here: <a href="https://docs.google.com/document/d/e/2PACX-1vSqWez5RR14P6z6PIRYQZ5aYgXM7KmT_qJLCODVBskHJVXihPogc3G6lZ4fau4lI-PFch9US82Eubmd/pub" title="Want to see my CV?" target="_blank" rel="noreferrer" className="inline-block align-middle"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-[#2f4181]" viewBox="0 0 20 20">
        <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
      </svg></a></p>

      </div>      
    </Layout>
  )

}

export default IndexPage